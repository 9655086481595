// // // src/ImageSlider.js
// // import React, { useState, useEffect } from "react";
// // import logos1 from "../Images/salesanalytics.jpg";
// // import logos2 from "../Images/poolpayBusinessImg.jpg";
// // import logos3 from "../Images/poolpayreports.jpg";
// // import logos4 from "../Images/salesanalytics.jpg";

// // const images = [
// //   logos1,logos2,logos3,logos4
// // ];

// // const ImageSlider = () => {
// //   const [currentIndex, setCurrentIndex] = useState(0);

// //   useEffect(() => {
// //     const intervalId = setInterval(() => {
// //       setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
// //     }, 2000); // Change image every 5 seconds

// //     return () => clearInterval(intervalId); // Cleanup interval on component unmount
// //   }, []);

// //   return (
// //     <div style={{ textAlign: "center" }}>
// //       <img
// //         src={images[currentIndex]}
// //         alt="Slider"
// //         style={{ width: "90vh", maxWidth: "600vh", height: "80vh",marginTop:"6vh" }}
// //       />
// //     </div>
// //   );
// // };

// // export default ImageSlider;

// import React, { useState, useEffect } from "react";
// import logos1 from "../Images/salesanalytics.jpg";
// import logos2 from "../Images/poolpayBusinessImg.jpg";
// import logos3 from "../Images/poolpayreports.jpg";
// import logos4 from "../Images/salesanalytics.jpg";

// const images = [
//   logos1, logos2, logos3, logos4
// ];

// const ImageSlider = () => {
//   const [currentIndex, setCurrentIndex] = useState(0);

//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
//     }, 2000); // Change image every 2 seconds

//     return () => clearInterval(intervalId); // Cleanup interval on component unmount
//   }, []);

//   return (
//     <div style={{ textAlign: "center", overflow: "hidden" }}>
//       <img
//         src={images[currentIndex]}
//         alt="Slider"
//         style={{
//           width: "100%",  // Make the image take up the full width of its container
//           height: "auto", // Maintain aspect ratio
//           maxWidth: "100%", // Ensure image does not exceed container width
//           maxHeight: "80vh", // Ensure image does not exceed viewport height
//           objectFit: "cover", // Ensure image covers the container without distortion
//           marginTop: "8rem" // Adjust margin if needed
//         }}
//       />
//     </div>
//   );
// };

// export default ImageSlider;

import React, { useState, useEffect } from "react";
import logos1 from "../Images/salesanalytics.jpg";
import logos2 from "../Images/poolpayBusinessImg.jpg";
import logos3 from "../Images/poolpayreports.jpg";
import logos4 from "../Images/salesanalytics.jpg";

const images = [
  // logos1,
  //  logos2,
  //  logos3,
    // logos4
];

const ImageSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 2000); // Change image every 2 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  return (
    <div style={{ textAlign: "center", overflow: "hidden", position: "relative" }}>
      <img
        src={images[currentIndex]}
        alt="Slider"
        style={{
          width: "100%",
          height: "auto",
          maxWidth: "100%",
          maxHeight: "calc(70vh - 15rem)", // Adjust max height with some margin
          objectFit: "cover",
          marginTop: "1rem", // Adjust margin to fit your design
          display: "block", // Remove inline spacing issues
          marginLeft: "auto",
          marginRight: "auto",
          
        }}
      />
    </div>
  );
};

export default ImageSlider;
