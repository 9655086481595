import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import payfiImage from '../Images/payfiwhiteimg.png'; // Update with the actual path to your image
import address from "../Images/address.png";

const Footer = () => {
  return (
    <footer
      className="text-white py-4 mt-4"
      style={{
        boxShadow: "2px 1px 2px 2px",
        width: "100%",
        backgroundColor: "#29305B",
        // backgroundColor: "#2158BC",
        border: "1px solid grey",
        borderRadius: '10px',
        marginLeft:"-17px"
      }}
    >
      <div className="container">
        <div className="row align-items-center">
          {/* Logo Section */}
          <div className="col-12 col-md-6 text-center text-md-start mb-3 mb-md-0">
            <img
              src={payfiImage}
              alt="Payfi Logo"
              className="img-fluid"
              style={{
                maxWidth: '210px',
                borderRadius: "5%",
                border: "1px solid white",
                background: "white",
              }}
            />
          </div>
          {/* Address Section */}
          <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end">
            <div className="d-flex flex-column align-items-center align-items-md-start ms-md-auto">
              <img
                className="mb-2"
                src={address}
                style={{
                  width: "2em",
                }}
                alt="Address Icon"
              />
              <p
                className="mb-0 text-center text-md-start"  // Changed from text-md-end to text-md-start
              >
                620, Tower B, Bhutani Alphathum, <br />
                Sector - 90, Noida 201305,<br />
                Email: care@payfi.co.in<br />
                Phone: +919560970986
              </p>
            </div>
          </div>
        </div>
        {/* Copyright Section */}
        <div className="row mt-4">
          <div className="col text-center">
            <p className="mb-0">&copy; 2024 Payfi, Inc. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
