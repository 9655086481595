import React from "react";
import Carousel from "react-bootstrap/Carousel";
import ani1 from "../Images/ani1.png";
import ani2 from "../Images/ani2.png";
import ani3 from "../Images/ani3.png";

const HomeScreenMock = () => {
  return (
    <Carousel interval={1000}> {/* Set interval to 5 seconds */}
      <Carousel.Item>
        <img
          className="d-block mx-auto"
          src={ani1}
          alt="First slide"
        //   style={{ width: "100%", height: "400px", objectFit: "contain" }} // Adjust width and height
          style={{margin:"3vh", width: "100%", height: "400px", objectFit: "contain" }} // Adjust width and height
          
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block mx-auto"
          src={ani2}
          alt="Second slide"
          style={{margin:"3vh", width: "100%", height: "400px", objectFit: "contain" }} // Adjust width and height
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block mx-auto"
          src={ani3}
          alt="Third slide"
          style={{margin:"3vh", width: "100%", height: "400px", objectFit: "contain" }} // Adjust width and height
        />
      </Carousel.Item>
    </Carousel>
  );
};

export default HomeScreenMock;
