import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const DownloadButton = () => {
    return (
      <div className="d-flex justify-content-start mt-8 mb-5">
      <a 
        href="/PoolpeBusinessPROD.apk" // Correct path, assuming file is in the public folder
        download="PoolpeBusinessPROD.apk"
        className="btn btn-primary text-white"
        style={{
          backgroundColor: "#2158BC",
          fontSize: "7px",
          padding: "2px 28px",
          margin: "9px 2vh",
          borderRadius: "8px",
          maxWidth: "100%",
          textAlign: "center",
          border: "none" // Removes any border
        }}
      >
        <p style={{
          color: "whitesmoke",
          fontSize: "9px",
          fontWeight: "bold",
          textAlign: "center",
          margin: "1vh -12px",
          marginBottom: "11px"
        }}>
          Download Poolpe business APK
          <br />
          
        </p>
      </a>
    </div>
    
    );
};

export default DownloadButton;
