// src/Home.js
import React from "react";
import DownloadButton from "../components/DownloadButton";
import HomeScreenMock from "../components/HomeScreenMock";
import ImageSlider from "./ImageSlider";
import Footer from "./Footer";

// import '../App.css';
import '../../src/App.css'
import empowerbusiness from "../Images/empowerbusi.jpg"


export default function Home() {
  return (
    <div style={{ backgroundColor: "white", minHeight: "100vh", padding: "20px"}}>
      <div className="fade-in-down">
        <h4 className="font-weight-bold" style={{textAlign:"left",marginLeft:"22px"}}><span style={{color:"#2158BC"}}>Best Payments Solution</span>  for Merchants</h4>
      </div> 
      <div style={{width:"150%",maxWidth:"50vw",margin:"2vh" }}>
        <DownloadButton />
      </div>
      <div>
          <img src={empowerbusiness} style={{maxWidth:"100vw",width:"100%",marginLeft:"0px"}}/>

      </div>
      {/* 173px */}
      {/* 270px */}
      {/* <div style={{border:"1px solid red", height: "180px", display: "flex", justifyContent: "center", width: "110%", margin: "0 -6vh 0vh -3vh" }}>
        <div style={{  borderRadius: "20px", overflow: "hidden", width: "100%", maxWidth: "85vw" }}>
          <ImageSlider />
        </div>
      </div> */}
      {/* border: "1px solid grey", boxShadow: "2px 1px 2px 2px" */}
      <div style={{ height: "500px", display: "flex", justifyContent: "center", width: "110%", margin: "3vh -5vh 0vh -3vh" }}>
        <div style={{  borderRadius: "20px", overflow: "hidden", width: "100%", maxWidth: "85vw" }}>
          <HomeScreenMock />
        </div>
      </div>
      <div style={{width:"110%",maxWidth:"110%"}}>
        <Footer />
      </div>
    </div>
  );
}
