import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HeadersFile from '../src/components/HeadersFile';
import Home from '../src/components/Home';
import TermCondition from '../src/components/TermCondition';


function App() {
  return (
    <Router>
      <div className="App">
        <HeadersFile />
        <main className="container mt-3">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/terms-and-conditions" element={<TermCondition />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
